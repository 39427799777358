import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// todo move this service to core
@Injectable({
  providedIn: 'root'
})
export class FormErrorsService {

  setFormErrors(resp: HttpErrorResponse, form: UntypedFormGroup): string {
    const error = resp.error;

    if (!error) {
      return;
    }

    form.setErrors({ server: error.detail });

    const errors = error.errors;

    if (errors) {
      Object.keys(errors).forEach(key =>
        form.get(key)?.setErrors({ server: errors[ key ] })
      );
    }

    return error.detail;
  }
}
